import { render, staticRenderFns } from "./SchedulesLayout.vue?vue&type=template&id=efae9ebc&scoped=true&"
import script from "./SchedulesLayout.vue?vue&type=script&lang=ts&"
export * from "./SchedulesLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efae9ebc",
  null
  
)

export default component.exports