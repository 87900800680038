

















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class EventSpeakersLayout extends Vue {
  eventId = this.$route.params.id
}
